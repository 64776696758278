import React, { useState } from "react";
import { Button } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";
const limit = 5;
const chartSliceFn = (array = [], page) => array.slice((page - 1) * limit, page * limit);


const Apaexlinecolumn = (props) => {
  const [page, setPage] = useState(1);  
  const totalLength = Math.max(...[props.y_axis?.length, props.y_axis_2?.length, props.y_axis_3?.length]) || 0;
  console.log('PROPS ---->>', props);

  const series = [
    {
      name: props.name,
      data: chartSliceFn(props.y_axis, page),
    },
    {
      name: props.name_2,
      data: chartSliceFn(props.y_axis_2, page),
    },
    {
      name: props.name_3,
      data: chartSliceFn(props.y_axis_3, page),
    }
  ];
  const options = {
    chart: {
      toolbar: {
        show: props.showToolBar,
      },
      "animations": {
        "enabled": true,
        "easing": "swing"
      },
      "background": "#FFFFFF",
      "dropShadow": {
        "enabled": true,
        "top": 1,
        "left": 1,
        "blur": 4,
        "color": "#000",
        "opacity": 0.1
      },
    },
    plotOptions: {
      bar: {
        horizontal: props.horizontal,
        borderRadius: 5,
        borderRadiusApplication: 'end',
        backgroundBarColors: '#000000',
        "colors": {
          "backgroundBarColors": [
              "#f1f2f3"
          ],
          "backgroundBarOpacity": 0
        },
        dataLabels: {
          position: "top",
        },
      },
      endingShape: "rounded",
    },
    dataLabels: {
      enabled: true,
      offsetY: props.dataEnabledOffsetY,
      offsetX: props.dataEnabledOffsetX,
      style: {
        colors: ["#000"],
        fontSize: '10px',
        fontFamily: 'Poppins'
      }
    },
    noData: {
      text: "No data Found",
      align: "center",
      verticalAlign: "middle",
      style: {
        color: "#7f7575",
        fontSize: '14px',
        fontFamily: 'Poppins'
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },

    colors: props.colors,
    xaxis: {
      sorted: true,
      type: 'category',
      categories: chartSliceFn(props.x_axis, page),
      "title": {
        "text": props.x_axis_title,
        "style": {
            cssClass: 'x_axis_label'
        },
        offsetY: props.offsetY
      },
      labels: {
        trim: true,
        formatter: (val) => {
          return val
        },
        style: {
          color: '#000000',
          cssClass: 'x_axis_content'
        },
        "offsetY": -2
      },
      tooltip: {
        enabled: true,
        offsetY: 100,
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        width: 6,
        offsetX: 0,
        offsetY: 0
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: 0,
        offsetY: -1
      }
    },
    yaxis: {
      title: {
        text: props.y_axis_title,
        style: {
          color: '#000000',
          cssClass: 'y_axis_label'
        }
      },
      axisBorder: {
        show: true,
        color: '#78909C',
        offsetX: 0,
        offsetY: -2
      },
      axisTicks: {
        show: true,
        borderType: 'solid',
        color: '#78909C',
        width: 6,
        offsetX: 0,
        offsetY: 0.5
      },
    },
    grid: {
      show: true,
      borderColor: "#E0E0E0",
      strokeDashArray: 3,
      padding: {
        left: 13,
        bottom: 0
      }
    },
    fill: {
      opacity: 1,
      type: 'solid'
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: props.legendPosition,
      horizontalAlign: props.horizontalAlign,
      offsetX: 0,
      offsetY: 0,
      showForNullSeries: false,
      showForZeroSeries: false
    },
  };

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'right',
      justifyItems: 'right',
      gap: '5px',
    }}> 
    <ReactApexChart options={options} series={series} type="bar" height={450} />
    <div style={{
      alignSelf: 'end',
      display: 'flex',
      gap: '5px',
    }}>
      <Button disabled={page === 1} onClick={() => {
        if (page > 1) {
          setPage(() => page - 1);
        }
      }}>
        {'<'}
      </Button>
      <Button disabled={(Math.ceil(totalLength / limit) === page)} onClick={() => {
        setPage(() => page + 1);
      }}>
        {'>'}
      </Button>
    </div>
    </div>
  );
};

export default Apaexlinecolumn;
