import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Button } from "react-bootstrap";
import { cloneDeep } from "lodash";

const limit = 5;
const chartSliceFn = (array = [], page) => array.slice((page - 1) * limit, page * limit);


const HorizontalBarchart = (props) => {
  const [page, setPage] = useState(1);
  const series = (cloneDeep(props.series) || []).map(s => {
    s.data = chartSliceFn(s.data, page);
    return s;
 });  

  const totalLength = Math.max(...[props.y_axis?.length]) || 0;

  const options = {
    chart: {
      type: "bar",
      stacked: true,
      horizontal: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    xaxis: {
      categories: chartSliceFn(props.y_axis, page),
    },
    legend: {
      position: "top",
      horizontalAlign: "left",
    },
    colors: props.colors,
  };
  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'right',
      justifyItems: 'right',
      gap: '5px',
    }}>
      <ReactApexChart options={options} series={series} type="bar" height="700" />
      <div style={{
        alignSelf: 'end',
        display: 'flex',
        gap: '5px',
      }}>
        <Button disabled={page === 1} onClick={() => {
          if (page > 1) {
            setPage(() => page - 1);
          }
        }}>
          {'<'}
        </Button>
        <Button disabled={(Math.ceil(totalLength / limit) === page)} onClick={() => {
          setPage(() => page + 1);
        }}>
          {'>'}
        </Button>
      </div>
    </div>
  );
};

export default HorizontalBarchart;
