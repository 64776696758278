import { React, Component } from "react";
import { connect } from "react-redux";
import {
  ALL_CONTENTS_FILTERS,
  ALPHATUB_PRODUCTS_OPTIONS,
  ANALYTICS_DATE_FILTER,
  GAMES_OPTIONS,
} from "../../../helpers/variables";

import * as analyticsAction from "../../../actions/analytics";
import { bindActionCreators } from "redux";
import Barchart from "../../../components/charts/apex/barchart";
import { ChartBaseComponent } from "../../../components/charts/chartBaseComponent";
import _, { noop } from "lodash";
import { Card, CardBody } from "reactstrap";
import Wrapper from "../../../Hoc/wrapper";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { DateRangeFilterPopup } from "components/tubClass/dateRangeFilterPopup";
import moment from "moment";
import ReactSelect from "react-select";
import Apaexlinecolumn from "components/charts/apex/apaexlinecolumn";
import HorizontalBarchart from "components/charts/apex/horizonatalBarchart";
import { graphqlOperation } from "aws-amplify";

const getAnalyticsColorBasedOnActivityLevelOfTeacher = (value) => {
  if (value >= 80) {
    return '#7ED957';
  } else if (value >= 60) {
    return '#FFDE59';
  }
  return '#ED7D2B';
}

class OverallClass extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contentOptions: ALL_CONTENTS_FILTERS,
      gamesOptions: GAMES_OPTIONS,
      filterOptions: ANALYTICS_DATE_FILTER,
      alphatubProductsOption: ALPHATUB_PRODUCTS_OPTIONS,
      noOfDays: "30",
      isScroll: false,
      showFilterDatePopup: false,
      selectedDateRange: null,
      selectedFilter: ANALYTICS_DATE_FILTER[0],
    };
  }
  componentDidMount() {
    this.setStickyTabs();
  }

  setStickyTabs = () => {
    let isScroll = false;
    window.addEventListener("scroll", () => {
      if (window.scrollY > 10) {
        isScroll = true;
      } else isScroll = false;
      this.setState({
        isScroll: isScroll,
      });
    });
  };

  triggerPopup = (val, data) => {
    if (val === "showFilterDatePopup") {
      this.unselectSelectedValue("filterByDate");
    }
    this.setState({
      [val]: !this.state[val],
    });
  };



  render() {
    let state = this.state;
    let { allGrpahsList } = this.props?.analytics;

    return (
      <>
        <div className="headerDashboard">
          <div
            className={`analytics_ovl_cl_main justify-content-center ${state.isScroll ? "sticky_bx_shadow" : ""
              }`}
          >
            <div className="col-md-2 col-5 px-3">
              <div className="custom-select-filter">
                <p>School Reports</p>
              </div>
              {state.selectedDateRange != null && (
                <p className="selected_date_range_filter">
                  ({state.selectedDateRange?.startDate} -{" "}
                  {state.selectedDateRange.endDate})
                </p>
              )}
            </div>
          </div>
        </div>

        <div className="mt-3 py-1">
          {/* Curriculum Progress */}
          <div className="report row no-gutters">
            {allGrpahsList[0]?.loading ? (
              <Skeleton height="200px" width="100%" />
            ) : (
              <div className="col-12">
                <Card>
                  <div
                    className="report_heading"
                    style={{ margin: "25px", marginLeft: "40px" }}
                  >
                    <p>Schoolwise Curriculum Progress</p>
                  </div>
                  <div className="boxes">
                    <div className="box-complete">
                    </div>
                    <span>Complete</span>
                    <div className="box-incomplete">
                    </div>
                    <span>Incomplete</span>
                  </div>
                  {allGrpahsList[0]?.graphData?.map((rs) => {
                    // rs.percentage = 50;
                    return (
                      <CardBody className="d-flex">
                        {/* <div className='col-3 p-0'>
                                            <div className='report_heading'>
                                                <p>Curriculum Progress</p>
                                            </div>
                                        </div> */}
                        <div className="col-12">
                          <>
                            <div>
                              {/* <p className='report_gp_1_total_count'>School Name:<span className='report_gp_1_total_count'>{rs?.SchoolName}</span></p> */}

                              <div className="curriculum_progress d-flex">
                                <div className="col-2">
                                  <p className="report_gp_1_total_count">
                                    {rs?.SchoolName}
                                  </p>
                                </div>
                                <div
                                  className="first_half"
                                  style={{ width: `${rs?.percentage}%` }}
                                >
                                  {rs?.percentage ? <p>{`${rs.percentage || 0}%`}</p> : <></>}
                                </div>
                                <div
                                  className="second_half"
                                  style={{
                                    width: `${100 - rs?.percentage < 0 ? 0 : 100 - rs?.percentage}%`,
                                  }}
                                >
                                  {/* { (100 - rs?.percentage || 0) > 0 ? <p>{`${  100 - rs?.percentage || 0} %`}</p> : <></>} */}
                                </div>
                              </div>

                              {/* <p className='report_gp_1_total_count'>Class Name:<span className='report_gp_1_total_count'>{rs?.class_Name}</span></p> */}
                            </div>
                            <div className="d-flex justify-flex-end mt-1">
                              <p className="report_gp_1_total_count">
                                {rs?.Total_Curriculum_Words === 0 ?
                                  '0 Words' : <>{rs?.Total_Explored} /{" "}
                                    {rs?.Total_Curriculum_Words} words</>
                                }

                              </p>
                            </div>
                          </>
                        </div>
                      </CardBody>)
                  })}
                </Card>
              </div>
            )}
          </div>

          {/* School Wise Vocabulary Progress */}
          <div className="row no-gutters mt-4 br-5">
            <div className="col-12 report">
              <Card>
                <CardBody>
                  {allGrpahsList[8]?.loading ? (
                    <Skeleton
                      style={{ marginTop: 15 }}
                      height="350px"
                      width="100%"
                    />
                  ) : (
                    <div className="row no-gutters justify-space-between">
                      <div className="col-12">
                        <div className="row no-gutters">
                          <div className="col-12">
                            <div className="report_heading">
                              <p>{"School Wise Vocabulary Progress"}</p>
                            </div>
                            <div className="main_graph mt-2">
                              <p className="horizontal_chrt_title"></p>

                              {allGrpahsList[8]?.graphData[0] &&
                                allGrpahsList[8]?.graphData[1]?.y_axis && (
                                  <HorizontalBarchart
                                    series={allGrpahsList[8]?.graphData[0]}
                                    y_axis={
                                      allGrpahsList[8]?.graphData[1]?.y_axis
                                    }
                                    showToolBar={false}
                                    y_axis_title=""
                                    x_axis_title="No Of Words"
                                    colors={[
                                      "#FFDE59",
                                      "#7ED957",
                                      "#ED7D2B",
                                      "#2f5f98",
                                      "#31356e",
                                      "#2ebbf0",
                                    ]}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>

          {/* Linear Graph Environmental Awareness Report */}
          <div className="row no-gutters mt-4 br-5">
            <div className="col-12 report">
              <Card>
                <CardBody>
                  {allGrpahsList[8]?.loading ? (
                    <Skeleton
                      style={{ marginTop: 15 }}
                      height="350px"
                      width="100%"
                    />
                  ) : (
                    <div className="row no-gutters justify-space-between">
                      <div className="col-12">
                        <div className="row no-gutters">
                          <div className="col-12">
                            <div className="report_heading">
                              <p>
                                {"Environmental Awareness Report"}
                              </p>
                            </div>
                            <div className="main_graph">
                              <p className="horizontal_chrt_title"></p>
                              {allGrpahsList[9]?.graphData[0] &&
                                allGrpahsList[9]?.graphData[1]?.y_axis && (
                                  <HorizontalBarchart
                                    series={allGrpahsList[9]?.graphData[0]}
                                    y_axis={
                                      allGrpahsList[9]?.graphData[1]?.y_axis
                                    }
                                    showToolBar={false}
                                    y_axis_title=""
                                    x_axis_title="No Of Words"
                                    colors={[
                                      '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#3F51B5', '#03A9F4', '#4CAF50',
                                      '#F9CE1D', '#FF9800', '#33B2DF', '#D4526E', '#13D8AA', '#A5978B', '#4ECDC4',
                                      '#C7F464', '#81D4FA', '#FD6A6A', '#2B908F', '#F9A3A4', '#90EE7E', '#FA4443',
                                      '#69D2E7', '#449DD1', '#F86624', '#EA3546', '#662E9B', '#C5D86D', '#D7263D', '#1B998B',
                                      '#2E294E', '#F46036', '#E2C044', '#662E9B', '#F86624', '#F9C80E', '#EA3546', '#43BCCD',
                                      '#5C4742', '#A5978B', '#8D5B4C', '#5A2A27', '#C4BBAF', '#A300D6', '#7D02EB', '#5653FE',
                                      '#2983FF', '#00B1F2'
                                    ]}
                                  />
                                )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </CardBody>
              </Card>
            </div>
          </div>

          {/* Total Students Vs Total Active Parents */}
          <div className="row mb-4">
            <div className="col-12">
              <ChartBaseComponent
                loading={allGrpahsList[6]?.loading}
                title={"Total Students Vs Total Active Parents"}
                report_number={"0006"}
                children={
                  <Apaexlinecolumn
                    x_axis={allGrpahsList[6]?.graphData?.x_axis}
                    y_axis={allGrpahsList[6]?.graphData?.y_axis}
                    y_axis_2={allGrpahsList[6]?.graphData?.y_axis_2}
                    y_axis_3={allGrpahsList[6]?.graphData?.y_axis_3}
                    // y_axis_title = {'Number Of Words'}
                    // x_axis_title={'Student'}
                    showToolBar={false}
                    name_3={"Active Parents"}
                    name_2={"Total Children"}
                    name={"Total Seats"}
                    dataEnabledOffsetY={-14}
                    dataEnabledOffsetX={0}
                    legendPosition={"top"}
                    horizontalAlign={"right"}
                    colors={["#00ADEF", "#ED7D2B", "#775DD0"]}
                  // offsetY = {parseInt(state.noOfDays) >= 30 ? 90 : 90}
                  />
                }
              />
            </div>
          </div>

          {/* Activity Level of Teachers Across Different Schools */}
          <div className="row mb-4">
            <div className="col-12">
              <ChartBaseComponent
                loading={allGrpahsList[1].loading}
                title={"Activity Level of Teachers Across Different Schools"}
                report_number={"0003"}
                children={
                  <HorizontalBarchart
                    series={[
                      {
                        name: "Total Count",
                        data: allGrpahsList[1].graphData?.x_axis,
                      },
                    ]}
                    y_axis={allGrpahsList[1].graphData?.y_axis}
                    // colors={['#00ADEF', '#00e396', '#ff4560', '#775dd0']}
                    // colors={getBarColors(allGrpahsList[2].graphData?.y_axis)}
                    dynamicColorsBasedOnValue={true}
                    colors={allGrpahsList[1].graphData.x_axis.map((ax) => getAnalyticsColorBasedOnActivityLevelOfTeacher(ax))}
                    // colors={[
                    //   "#7ED957",
                    //   "#FFDE59",
                    //   "#ED7D2B",
                    //   "#2f5f98",
                    //   "#31356e",
                    //   "#2ebbf0",
                    // ]}
                    showToolBar={false}
                    y_axis_title=""
                    x_axis_title="Total Count"
                  />
                }
              />
            </div>
          </div>

          {/* Activity Level of Parents Across Different Schools */}
          <div className="row mb-4">
            <div className="col-12">
              <ChartBaseComponent
                loading={allGrpahsList[4].loading}
                title={"Activity Level of Parents Across Different Schools"}
                report_number={"0003"}
                children={
                  <HorizontalBarchart
                    series={[
                      {
                        name: "Total Count",
                        data: allGrpahsList[4]?.graphData?.x_axis,
                      },
                    ]}
                    y_axis={allGrpahsList[4]?.graphData?.y_axis}
                    // colors={['#00ADEF', '#00e396', '#ff4560', '#775dd0']}
                    // colors={getBarColors(allGrpahsList[2].graphData?.y_axis)}
                    colors={[
                      "#FFDE59",
                      "#7ED957",
                      "#ED7D2B",
                      "#2f5f98",
                      "#31356e",
                      "#2ebbf0",
                    ]}
                    showToolBar={false}
                    y_axis_title=""
                    x_axis_title="Total Count"
                  />
                }
              />
            </div>
          </div>

          {/* Average Score of each Child for particular period */}
          <div className="row mb-4">
            <div className="col-12">
              <ChartBaseComponent
                loading={allGrpahsList[3]?.loading}
                title={"Average Score of Each School for a Particular Period"}
                customText={"AP: Active Participants"}
                report_number={"0004"}
                children={
                  <Barchart
                    x_axis={allGrpahsList[3]?.graphData?.x_axis}
                    y_axis={allGrpahsList[3]?.graphData?.y_axis}
                    y_axis_title={"Average Score"}
                    name={"Average Score for the Period Selected"}
                    showToolBar={false}
                    miscellaneousValues={allGrpahsList[3]?.graphData.miscellaneousValues}
                    dataLabels={{
                      enabled: true,
                      style: {
                        fontSize: '11px',
                        fontFamily: 'Helvetica, Arial, sans-serif',
                        wordWrap: 'break-word',
                        maxWidth: '25%',
                        fontWeight: 'bold',
                        colors: ['#000000']
                      },
                      formatter: function (val, opt) {
                        const miscellaneous = opt?.config?.miscellaneousValues;
                        return `AP: ${miscellaneous[opt.dataPointIndex]}`
                      }
                    }}

                    // x_axis_title = {'Students'}
                    colors={["#ED7D2B"]}
                  />
                }
              />
            </div>
          </div>

          {/* Assignment Completion Graph */}
          <div className="row mb-4">
            <div className="col-12">
              <ChartBaseComponent
                loading={allGrpahsList[2]?.loading}
                title={"Assignment Completion Graph"}
                report_number={"0003"}
                children={
                  <Barchart
                    x_axis={allGrpahsList[2]?.graphData?.x_axis}
                    y_axis={allGrpahsList[2]?.graphData?.y_axis}
                    y_axis_title={"Number of Assignments Completed"}
                    // x_axis_title = {'Students'}
                    showToolBar={false}
                    name={"No of Assignments Completed"}
                    // colors={getBarColorss(allGrpahsList[2].graphData?.y_axis)}
                    colors={["#00ADEF"]}
                  />
                }
              />
            </div>
          </div>

          {/* Parent Wise Categories Report */}
          <div className="row mb-4">
            <div className="col-12">
              <ChartBaseComponent
                loading={allGrpahsList[7]?.loading}
                title={"Parent Wise Categories Report"}
                report_number={"0007"}
                children={
                  <Barchart
                    x_axis={allGrpahsList[7]?.graphData?.x_axis}
                    y_axis={allGrpahsList[7]?.graphData?.y_axis}
                    // y_axis_title = {'Accuracy'}
                    name={"Total Categories"}
                    showToolBar={false}
                    // x_axis_title={'Students'}
                    colors={["#6759a2"]}
                  // colors={getBarColors(allGrpahsList[7].graphData?.y_axis)}
                  />
                }
              />
            </div>
          </div>
        </div>

        {/* Filter date popup*/}
        <DateRangeFilterPopup
          showFilterDatePopup={state.showFilterDatePopup}
          triggerPopup={this.triggerPopup}
          onChangeFilterDate={this.onChangeFilterDate}
        />
      </>
    );
  }
}

const mapStateToProps = ({
  myProfile,
  loader,
  classes,
  analytics,
  dataToPersist,
}) => {
  return {
    myProfile,
    loader,
    classes,
    analytics,
    dataToPersist,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    analyticsAction: bindActionCreators(analyticsAction, dispatch),
    dispatch,
  };
};

const Overall = Wrapper(
  connect(mapStateToProps, mapDispatchToProps)(OverallClass)
);

export { Overall };
